import React from "react"

import PropTypes from "prop-types"
import {
  formatDate,
  formatDateShortYear,
  formatFullDate,
} from "../../helpers/dates"
import FormLink from "../../components/FormLink"

import { FcInfo } from "react-icons/fc"
import "react-tooltip/dist/react-tooltip.css"
import { Tooltip } from "react-tooltip"

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

const MobileBanner = ({ dates, highlights, displayBanner, page }) => {
  const language = process.env.GATSBY_SITE_LANGUAGE

  const date = dates[0]
  const prePaidPrice = date?.prePaidPrice
  const standardPrice = date?.standardPrice

  const startDate = date && formatDate(date.startDate)
  const endDate = date && formatDateShortYear(date.endDate)

  if (!dates.length) return null

  let title
  let subtitle
  if (dates.length === 1) {
    title = `${startDate} - ${endDate} ${date.price && ` • ${date.price}`}`
    subtitle = highlights !== null ? highlights : ""
  }
  if (dates.length > 1) {
    title = `${formatDateShortYear(date.startDate)} & ${
      dates.length - 1
    } more date${dates.length - 1 > 1 ? "s" : ""}`
    subtitle = `${date.price !== null ? date.price : ""} ${
      highlights !== null
        ? highlights
        : `${date.price !== null ? "(incl VAT)" : ""}`
    }`
  }

  const displayClasses = displayBanner
    ? "visible pointer-events-auto"
    : "invisible pointer-events-none"

  return (
    <div
      className={`fixed right-0 bottom-0 left-0 z-10 py-4 bg-white duration-300 shadow-card transition-visibility ${displayClasses}`}
    >
      <div className="container">
        <div className="flex justify-between items-center text-gray-800">
          <div className="text-sm leading-tight">
            <p className="font-bold">{title}</p>
            <p>{subtitle}</p>
          </div>
        </div>

        <div className="flex gap-4 items-center mb-4 w-full">
          <div className="flex mr-auto">
            <strong>
              {language === "en" ? "Early Bird Rate" : "Tariffa Early Bird"}
            </strong>
            <Tooltip id="early-bird" place="top" className="z-[1000]" />
            <a
              data-tooltip-id="early-bird"
              data-tooltip-html={
                language === "en"
                  ? "This temporary rate with 20% discount on<br />the Standard Rate requires a 25% non-refundable<br /> deposit. The rest to be paid one month<br /> before arrival"
                  : "Questa tariffa temporanea con 20% sconto sulla tariffa standard<br />richiede un deposito non rimborsabile del 25%. Il resto deve<br /> essere pagato un mese prima dell'arrivo."
              }
            >
              <FcInfo className="ml-1" />
            </a>
          </div>
          <div className="mx-1 shrink-0">
            <p className="text-xs">
              <em>{language === "en" ? "Price from" : "Prezzo da"}</em>
            </p>
            <p>
              €{numberWithCommas(prePaidPrice)}
              <span className="text-sm">p.p.</span>
            </p>
          </div>
          <div className="w-[100px] shrink-0">
            <FormLink
              linkLabel={language === "en" ? "Book Now" : "Prenota ora"}
              form="Retreat Enquiry - Early"
              page={page}
              type="buttonBooking"
              rate="Early Bird Rate"
            />
          </div>
        </div>
        <div className="flex gap-4 items-center w-full">
          <div className="flex mr-auto">
            <strong>
              {language === "en" ? "Standard Rate" : "Tariffa Standard"}
            </strong>
            <Tooltip id="standard-price" place="top" className="z-[1000]" />
            <a
              data-tooltip-id="standard-price"
              data-tooltip-html={
                language === "en"
                  ? "This rate requires a 25% refundable<br /> deposit if cancelled at least one month before<br /> arrival. The rest to be paid upon arrival."
                  : "Questa tariffa richiede un deposito rimborsabile<br />del 25% se annullata almeno un mese prima<br /> dell'arrivo. Il resto da pagare prima o all'arrivo."
              }
            >
              <FcInfo className="ml-1" />
            </a>
          </div>
          <div className="mx-1 shrink-0">
            <p className="text-xs">
              <em>{language === "en" ? "Price from" : "Prezzo da"}</em>
            </p>
            <p>
              €{numberWithCommas(standardPrice)}
              <span className="text-sm">p.p.</span>
            </p>
          </div>
          <div className="w-[100px] shrink-0">
            <FormLink
              linkLabel={language === "en" ? "Book Now" : "Prenota ora"}
              form="Retreat Enquiry - Standard"
              page={page}
              type="buttonBooking"
              rate="Standard Rate"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileBanner

MobileBanner.propTypes = {
  dates: PropTypes.array,
  highlights: PropTypes.string,
  displayBanner: PropTypes.bool,
  page: PropTypes.string,
}
