import React, { useState } from "react"
import PropTypes from "prop-types"
import "react-tooltip/dist/react-tooltip.css"
import { Tooltip } from "react-tooltip"
import { FcInfo } from "react-icons/fc"

import { useSearchParameters } from "../../stores/searchParameters"
import FormLink from "../../components/FormLink"

function calculateNights(checkInDate, checkOutDate) {
  const oneDay = 24 * 60 * 60 * 1000 // Number of milliseconds in a day
  const checkIn = new Date(checkInDate)
  const checkOut = new Date(checkOutDate)
  const diffDays = Math.round(Math.abs((checkOut - checkIn) / oneDay))
  return diffDays
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

const MobileBanner = ({ prePaidPrice, standardPrice, displayBanner, page }) => {
  const language = process.env.GATSBY_SITE_LANGUAGE

  const [dateRange, setDateRange] = useState()
  const displayClasses = displayBanner
    ? "visible pointer-events-auto"
    : "invisible pointer-events-none"

  const { checkInDate, checkOutDate, totalGuests, totalRooms } =
    useSearchParameters()

  const totalNights = calculateNights(checkInDate, checkOutDate)

  if (totalNights === 0 || !checkInDate || !checkOutDate) return

  return (
    <div
      className={`fixed right-0 bottom-0 left-0 z-10 py-4 bg-white duration-300 shadow-card transition-visibility ${displayClasses}`}
    >
      <div className="container">
        <div className="flex justify-between">
          <p className="mb-2">
            {language === "en" ? "Guests" : "Ospiti"}:{" "}
            <strong>{totalGuests}</strong>
          </p>
          <p className="mb-2">
            {language === "en" ? "Rooms" : "Camere"}:{" "}
            <strong>{totalRooms}</strong>
          </p>
          <p className="mb-6">
            {language === "en" ? "Nights" : "Notti"}:{" "}
            <strong>{totalNights}</strong>
          </p>
        </div>
        <hr className="mb-2" />
        <div className="flex gap-4 items-center mb-4 w-full">
          <div className="flex mr-auto">
            <strong>
              {language === "en" ? "Early Bird Rate" : "Tariffa Early Bird"}
            </strong>
            <Tooltip id="early-bird" place="top" className="z-[1000]" />
            <a
              data-tooltip-id="early-bird"
              data-tooltip-html={
                language === "en"
                  ? "This temporary rate with 20% discount on<br />the Standard Rate requires a 25% non-refundable<br /> deposit. The rest to be paid one month<br /> before arrival"
                  : "Questa tariffa temporanea con 20% sconto sulla tariffa standard<br />richiede un deposito non rimborsabile del 25%. Il resto deve<br /> essere pagato un mese prima dell'arrivo."
              }
            >
              <FcInfo className="ml-1" />
            </a>
          </div>
          <div className="mx-2 shrink-0">
            <p className="text-xs">
              <em>{language === "en" ? "Price" : "Prezzo"} p.p.</em>
            </p>
            <p>€{numberWithCommas(prePaidPrice * totalNights)}</p>
          </div>
          <div className="w-[100px] shrink-0">
            <FormLink
              linkLabel={language === "en" ? "Book Now" : "Prenota ora"}
              form="Booking Enquiry - Early"
              page={page}
              type="buttonBooking"
            />
          </div>
        </div>
        <div className="flex gap-4 items-center w-full">
          <div className="flex mr-auto">
            <strong>
              {language === "en" ? "Standard Rate" : "Tariffa Standard"}
            </strong>
            <Tooltip id="standard-price" place="top" className="z-[1000]" />
            <a
              data-tooltip-id="standard-price"
              data-tooltip-html={
                language === "en"
                  ? "This rate requires a 25% refundable<br /> deposit if cancelled at least one month before<br /> arrival. The rest to be paid upon arrival."
                  : "Questa tariffa richiede un deposito rimborsabile<br />del 25% se annullata almeno un mese prima<br /> dell'arrivo. Il resto da pagare prima o all'arrivo."
              }
            >
              <FcInfo className="ml-1" />
            </a>
          </div>
          <div className="mx-2 shrink-0">
            <p className="text-xs">
              <em>{language === "en" ? "Price" : "Prezzo"} p.p.</em>
            </p>
            <p>€{numberWithCommas(standardPrice * totalNights)}</p>
          </div>
          <div className="w-[100px] shrink-0">
            <FormLink
              linkLabel={language === "en" ? "Book Now" : "Prenota ora"}
              form="Booking Enquiry - Standard"
              page={page}
              type="buttonBooking"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileBanner

MobileBanner.propTypes = {
  bookingID: PropTypes.number,
  page: PropTypes.string,
  displayBanner: PropTypes.bool,
}
