import React from 'react'
import PropTypes from 'prop-types'

import loadable from '@loadable/component'

const FormLink = loadable(() => import('../../components/FormLink'))

const MobileBanner = ({
  title,
  bookingID,
  price,
  displayBanner,
  highlights,
}) => {
  const displayClasses = displayBanner
    ? 'visible pointer-events-auto'
    : 'invisible pointer-events-none'

  const openBookingSite = evt => {
    evt.preventDefault()
    Booking.Open({ OfferID: bookingID })
  }

  return (
    <div
      className={`fixed right-0 bottom-0 left-0 py-4 bg-white shadow-card transition duration-300 transition-visibility z-10 ${displayClasses}`}
    >
      <div className="container">
        <div className="flex items-center justify-between text-gray-800">
          <div className="leading-tight text-sm">
            <p className="font-bold">
              {title}
              {price && ` • ${price}`}
            </p>
            {highlights && <p>{highlights}</p>}
          </div>

          <div className="flex flex-shrink-0">
            <FormLink
              linkLabel="Ask us / the host a question"
              form="Coaching Enquiry"
              page={title}
              icon
            />
            <a
              href="#book"
              className="button relative inline-flex rounded-4 text-sm leading-none font-bold bg-aqua-500 text-white"
              onClick={openBookingSite}
            >
              <span>Book now</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileBanner

MobileBanner.propTypes = {
  title: PropTypes.string,
  bookingID: PropTypes.number,
  price: PropTypes.string,
  displayBanner: PropTypes.bool,
  highlights: PropTypes.string,
}
